import React from 'react'
import PropTypes from 'prop-types'

const ProfileCard = ({ data }) => {
  return (
    <div className="flex items-center lg:space-x-16">
      <div className="hidden lg:block w-96">
        <img className="" src={data.image} alt={data.name} />
      </div>
      <div className="flex-1">
        <h3 className="font-bold text-xl lg:text-5xl mb-7">{data.name}</h3>
        <div>{data.description}</div>
      </div>
    </div>
  )
}

ProfileCard.propTypes = {
  data: PropTypes.object
}

export default ProfileCard
