import React from 'react'

const Footer = () => {
  return (
    <footer id="contact" className="overflow-x-hidden">
      <div className="bg-theme-1">
        <div className="container mx-auto py-16">
          <div className="flex flex-col items-center space-y-4">
            <div className="text-xl font-bold">
              For more information, please contact:
            </div>

            <div className="flex flex-col lg:flex-row items-center">
              <a href="mailto://replacementpoliticsconference@gmail.com" className="lg:text-xl font-light hover:underline">
                replacementpoliticsconference@gmail.com
              </a>
              <span className="lg:text-xl font-light hover:underline">&nbsp;or&nbsp;</span>
              <a href="mailto://l.m.hernadezaguilar@uva.nl" className="lg:text-xl font-light hover:underline">
                l.m.hernadezaguilar@uva.nl
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-theme-2">
        <div className="container mx-auto ">
          <div className="flex justify-center">
            <div className="py-8 text-white text-sm opacity-60">
              © Copyright 2021 - 2018. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

