import React, { useState } from 'react'
import ScrollSpy from 'react-scrollspy'
import * as $ from 'jquery'

const navigationItems = [
  {
    name: 'About',
    id: 'about'
  },
  {
    name: 'Call for papers',
    id: 'call-for-papers'
  },
  {
    name: 'Keynote sessions',
    id: 'speakers-and-respondents',
  },
  {
    name: 'Programme',
    id: 'programme',
  },
  {
    name: 'Registration',
    id: 'registration',
  },
  {
    name: 'Organization',
    id: 'organization',
  },
  {
    name: 'Institutional Affiliations',
    id: 'institutional-affiliations',
  },
  {
    name: 'Contact',
    id: 'contact',
  },
]

const Header = () => {
  const initialOffset = 104

  const [isOpen, setStatus] = useState(false)
  const [offset] = useState(initialOffset)

  const toggleStatus = (status) => {
    setStatus(status)
  }

  const handleScroll = (e) => {
    const hash = e.target.hash
    const desiredElement = $(hash)
    const headerMenu = $('header .menu')

    if (desiredElement.length) {
      e.preventDefault()

      const offset = document.documentElement.clientWidth > 1024
        ? initialOffset : headerMenu.height() + 20

      $('html, body').animate({
        scrollTop: desiredElement.offset().top - offset
      })
    }
  }

  return (
    <header className="lg:fixed inset-x-0 bg-white z-10">
      <div className="container mx-auto">
        <div className="w-full flex flex-col items-center space-y-4 my-3">
          <div className="w-full">
            <div className="flex justify-between lg:justify-center items-center">
              <h1 className="font-bold lg:text-4xl text-theme-2">
                <a href="/">
                  The Politics of Replacement
                </a>
              </h1>
              <button onClick={() => toggleStatus(!isOpen)} className="block lg:hidden focus:outline-none">
                <i className="fas fa-bars"></i>
              </button>
            </div>
          </div>

          <ScrollSpy
            items={navigationItems.map(({ id }) => id)}
            currentClassName="text-theme-2 font-bold"
            className={`${isOpen ? '' : 'hidden'} menu lg:flex flex-col lg:flex-row items-center lg:space-x-10 text-base list-none`}
            offset={(-1 * offset)}
          >
            {navigationItems.map((item, i) => (
              <li className="hover:text-theme-2" onClick={() => toggleStatus(false)} key={i}>
                <a onClick={handleScroll} href={`#${item.id}`}>{item.name}</a>
              </li>
            ))}
          </ScrollSpy>
        </div>
      </div>
    </header>
  )
}

export default Header
