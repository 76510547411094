import SarahBrackeImage from '../images/sarah-bracke.png'
import LuisManuelHernandezAguilarImage from '../images/luis-manuel-hernandez-aguilar.png'
import MelanieHaijeImage from '../images/melanie-haije.png'
import PilarDaloImage from '../images/pilar-dalo.png'
import SherilynDeenImage from '../images/sherilyn-deen.png'
import RoxaneKroonImage from '../images/roxane-kroon.png'
import LouMoussetImage from '../images/lou-mousset.png'
import BernaToprakImage from '../images/berna-toprak.png'
import AslihanOzturkImage from '../images/aslihan-ozturk.png'

export default {
  convenors: [
    {
      name: 'Sarah Bracke',
      image: SarahBrackeImage,
      description:
        'Sarah Bracke is Professor of Sociology of Gender and Sexuality at the University of Amsterdam and co-directs the Amsterdam\nResearch Centre for Gender and Sexuality. She is the Principle Investigator of the EnGendering Europe’s ‘Muslim Question’\nresearch project funded by the Netherlands Scientific Council (NWO). Her research focuses on the intersections of gender and\nsexuality with processes of culturalization and racialization in Europe – both as a symbolic and material construction. Her work\nis published in journals such as European Journal for Women’s Studies; Theory, Culture & Society; Religion and Gender; Journal\nof Diversity and Gender Studies; Cultural Studies; Journal of Muslims in Europe; and the British Journal of Sociology. She’s an\nExecutive Editor of the SAGE journal Ethnography.\n',
    },
    {
      name: 'Luis Manuel Hernández Aguilar',
      image: LuisManuelHernandezAguilarImage,
      description:
        'Luis Manuel Hernández Aguilar is a researcher at the University of Amsterdam, working on the project EnGendering Europe’s\nMuslim Question. He holds a Ph.D. in Sociology by the Goethe-University Frankfurt am Main. Among his publications:\n(2018) Governing Muslims and Islam in Contemporary Germany, Race, Time, and the German Islam Conference, Leiden & Boston:\nBrill: (2020) The Interior Frontiers of Germany, On Recursive History and Ritual Male Circumcision. Journal of Muslims in Europe,\n10(1), 22–44; (Bracke & Hernández Aguilar 2020) “They love death as we love life”: The “Muslim Question” and the biopolitics of\nreplacement, British Journal of Sociology, 71(4): 680-701.\n',
    },
  ],
  team: [
    {
      name: 'Melanie Haije',
      image: MelanieHaijeImage,
      description:
        'As a project manager I have been organizing scientific conferences for the University of Amsterdam for the last 6 years. Since\nthe pandemic we have made a switch to online conferencing. However, I hope we can go back to a hybrid and/or physical\nversion of conferences in the near future. Together with my creative team in wonderful Amsterdam, we make the most of each\nevent; online, offline, and hybrid!\n',
    },
    {
      name: 'Pilar dʼAlò',
      image: PilarDaloImage,
      description:
        'Pilar d’Alò is a PhD candidate in Political Sociology at the University of Newcastle, UK. Her interest lies with gender & sexuality\nstudies, critical race studies, post- and de-coloniality and religion & spirituality studies. In her doctoral project, she is\ninvestigating how modern/colonial ‘gender and sexuality’ politics and knowledge production are challenged and/or reproduced\nby how Argentinean/Latin American leading feminist movements are articulating their relationship to ‘spiritual’ standpoints,\nand to Black and Indigenous claims.\n',
    },
    {
      name: 'Sherilyn Deen',
      image: SherilynDeenImage,
      description:
        'Sherilyn Deen is a PhD candidate in the Department of Sociology at the University of Amsterdam. Her PhD project is part of the\nlarger NWO-VICI project EnGendering Europe’s Muslim Question. In her own project, Sherilyn focuses on analyzing statistical\nknowledge production on ‘mixed’ marriages and relationships (gemengde huwelijken en relaties) in the Netherlands, and how\nthis relates to the problematization of Muslims in public debates. She is interested in how notions of ‘mixing’ are produced in\nboth contemporary and historical population statistics, how knowledge on ‘mixing’ circulates, and how this sheds light on the\nentanglements of race, religion, reproduction and the nation. For her project, Sherilyn draws from and combines insights from\nScience and Technology Studies, Critical Race Studies, Postcolonial Studies and Gender and Sexuality studies. Her PhD project is\nsupervised by prof. dr. Sarah Bracke and prof. dr. Amade M’charek.\n',
    },
    {
      name: 'Roxane Kroon',
      image: RoxaneKroonImage,
      description:
        'Roxane Kroon has a background in cultural anthropology with a focus on migration and gender. She recently graduated cum\nlaude from the Research MA Gender Studies at Utrecht University. Currently, she works as the project coordinator of the Focus\nArea Migration and Societal Change also at Utrecht University. Her interests lie at the intersection of gender, sexuality, critical\nwhiteness studies and affect. In her latest project she researched expressions of sexualized racism against asylum seekers in the\nNetherlands. By combining discourse analysis and ethnographic methods, she traced the rise of the notion ‘rapefugee’\nthroughout Dutch history of public debates in migration and combined this with an analysis of self-representations of\nprotesters against asylum seekers in order to understand affective expressions of Dutch white innocence and sexual\nnationalism.\n',
    },
    {
      name: 'Lou Mousset',
      image: LouMoussetImage,
      description:
        'Lou Mousset is a PhD candidate in the Department of Sociology at the University of Amsterdam. Her PhD project is part of the\nlarger NWO-VICI project EnGendering Europe’s Muslim Question. The working title of her research is Politics of decline; gender,\nrace and the death of France and attempts to further theorize the systemic problematization of Muslims in France by focusing\non the pervasiveness of the narrative of national decline. Although ‘decline’ commonly refers to a movement towards an end,\nthe production of discourses of national decline seems to be never ending. In recent years, the dark tale of national decline has\nbeen reiterated at the top of the book chart by media figures who link the ‘end of France’ to its impending Islamization. This\nresearch proposes to critically explore this discourse of national decline by contextualizing it as part of a longer genealogy\nwhere the Nation of France is enacted by the construction of its enemy, on grounds that are conjointly civilizational and sexual.\n',
    },
    {
      name: 'Aslıhan Öztürk',
      image: AslihanOzturkImage,
      description:
        'Aslıhan Öztürk has a background in history and sociology in Gender and Sexuality. She’s currently working as a research\nassistant for the NWO-VICI project EnGendering Europe’s Muslim Question, focus on and keeping track of the problematization\nof Muslims in the Dutch and Flemish media.\n',
    },
    {
      name: 'Berna Toprak',
      image: BernaToprakImage,
      description:
        'Berna Toprak is a PhD candidate in Political Sociology at the University of Amsterdam. Her interests lie at the intersection of\ngender & sexuality studies, critical race studies, critical Muslim Studies and psychoanalysis. Her current project investigates the\nconfiguration of the ‘Muslim Question’ through discourses of gendered violence, with a special focus on racialized masculinities.\nBy combining discourse analysis and an ethnographic approach she aims to understand how Muslim men are problematized,\nwhat this problematization produces and, inspired by W.E.B. Du Bois, how this is experienced by Muslim men. This project is\npart of the larger NWO-VICI project EnGendering Europe’s Muslim Question and is supervised by prof. dr. Sarah Bracke and prof.\ndr. Nadia Fadil. Berna completed a BA in Communications Sciences at the University of Amsterdam and a research MA in\nGender & Ethnicity at Utrecht University.\n',
    },
  ],
}
