import React from 'react'
import PropTypes from 'prop-types'
import SpeakersData from '../data/SpeakersData'
import OrganizationData from '../data/OrganizationData'
import Helmet from 'react-helmet'

import ProfileCard from '../components/ProfileCard'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SeparatorHeader from '../components/SeparatorHeader'
import PDFViewer from '../components/PDFViewer'

import PosterImage from '../images/poster.png'
import RaceReligionSecularismImage from '../images/race-religion-secularism.png'
import PoliticalSociologyImage from '../images/political-sociology.png'
import NetherlandsOrganizationImage from '../images/netherlands-organization.png'
import UniversityOfAmsterdamImage from '../images/university-of-amsterdam.png'

const ProfilePair = ({ data }) => (
  <>
    <ProfileCard data={data[0]} />
    <SeparatorHeader type="first" className="my-12">
      In conversation with
    </SeparatorHeader>
    <ProfileCard data={data[1]} />
  </>
)

ProfilePair.propTypes = {
  data: PropTypes.array,
}

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>EEMQ - The Politics of Replacement</title>
        <body className="font-montserrat font-light"></body>
      </Helmet>

      <Header />

      <section className="lg:h-screen py-20 lg:py-0 bg-theme-1 relative">
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="container">
            <div className="flex flex-col items-center space-y-8">
              <div className="text-center font-bold text-2xl leading-normal">
                Welcome to the website
                <br />
                of the international academic conference
              </div>

              <div className="font-bold text-center lg:text-6xl leading-normal text-theme-2">
                The Politics of Replacement.
                <br />
                Demographic Fears, Conspiracy Theories,
                <br />
                and Race Wars.
              </div>

              <div className="text-center font-medium leadning-normal">
                The conference is part of ongoing research in the framework
                <br />
                of the EnGendering Europe’s ‘Muslim Question’ research
                <br />
                project funded by the Netherlands Scientific Council (NWO).
              </div>
            </div>
          </div>

          <a href="#about" className="hidden lg:block absolute bottom-0 inset-x-0 mb-20">
            <div className="flex flex-col items-center justify-center">
              <div className="w-12 h-12 border border-t-0 border-l-0 border-black transform origin-center rotate-45	"></div>
            </div>
          </a>
        </div>
      </section>

      <section id="about" className="min-h-screen bg-theme-gray py-20">
        <div className="container max-w-screen-xl mx-auto">
          <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-20 items-center">
            <div className="lg:w-1/2">
              <img className="w-full" src={PosterImage} alt="" />
            </div>
            <div className="lg:w-1/2">
              <div className="flex flex-col space-y-7">
                <div className="font-medium leading-relaxed text-lg prose">
                  <p>
                    First scheduled to take place in June 2020, the global
                    pandemic forced us to postpone the conference. While for a
                    long time we remained committed to organizing a real life
                    gathering in Amsterdam, at some point we decided that an
                    international gathering of scholars who have dedicated time
                    and energy to critically study the rise and the constitution
                    of replacement conspiracies cannot wait.
                  </p>

                  <p>
                    This means that the conference will, to a large extent, be
                    an online conference, and speakers and participants will
                    meet each other through the online platform MeetAnyway. With
                    a hybrid touch: some of the keynote speeches and panels will
                    physically take place in Amsterdam and will be livestreamed.
                  </p>

                  <p>
                    In the following weeks, we will add the full programme and
                    other conference logistics on this site. Keep an eye on this
                    space for updates. We are looking forward to meeting you in
                    June!
                  </p>
                </div>
                <div className="font-bold">The conference team</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="call-for-papers" className="min-h-screen bg-theme-1 py-16">
        <div className="container mx-auto">
          <div className="flex flex-col items-center space-y-8">
            <div className="text-center font-bold text-xl lg:text-4xl text-theme-2 lg:leading-relaxed">
              Call for Papers:
              <br />
              The Politics of Replacement
              <br />
              Demographic Fears, Conspiracy Theories, and Race Wars
            </div>

            <div className="container max-w-screen-md font-medium prose">
              <p>
                Discourses and conspiracies about national populations under
                threat of being overtaken or even wiped out by those considered
                as ‘alien’ to the nation body are on the rise, yet again. From
                Eurabia fantasies to Camus’ The Great Replacement, from ‘Jews
                will not replace us’ (Unite the Right rally in Charlottesville)
                to ‘It’s the birthrates’ (in the Christchurch killer’s
                manifesto), white supremacist discourses are thriving and
                increasingly distributed in mainstream venues. These discourses
                rely on defining ontological distinctions between who belongs to
                the nation and who is ‘alien’, and visions about the
                reproduction of that nation, notably about who should procreate
                more and whose procreation is alarming and should be halted.
              </p>
              <p>
                Narratives of ‘waves’ or ‘floods’ of migration as well as on
                high birth and fertility rates among ‘migrant’ populations have
                proliferated all over Europe in the past decades, and provided
                fertile ground for the ‘fear of replacement’. Particularly after
                the 9/11 attacks and the ensuing ‘War on Terror’ a discourse
                problematizing the presence and existence of Muslims in the
                ‘West’ has burgeoned all over Europe, often taken the shape of
                the fear of ‘Islamization’, i.e., the substitution of European
                values, norms, and culture by Islamic ones. While this
                problematization is a multifaceted phenomenon that engages with
                notions and constructions of citizenship, racial
                characterizations, migrations, anxieties hovering around gender
                and sexuality, and ideas mobilized in regard to ‘Islamic’
                violence, it is also centered in a discourse with variegated
                labels and signatures (Demographic Jihad, Muslim fecundism, the
                secret plot of Eurabia, the Great Replacement) that postulates a
                deliberative and conspiratorial process whereby Muslims have
                begun to ‘replace’ the ‘native’ populations of Europe. Concerns
                about ‘Muslim demographics’ within Europe, moreover, have been
                entertained, mobilized, and deployed to not only construct
                Muslims as problems and dangers to the present and future of
                Europe, but also as calls to revive eugenic policies aimed to
                gain control and determine the social, cultural, and ‘racial’
                make up of European societies.
              </p>
              <p>
                While we began investigating fears of ‘replacement’ in relation
                to Europe’s ‘Muslim Question’ today, we recognize replacement
                discourse as multiple and versatile, and unfolding in different
                contexts, and having different historical genealogies. This
                conference seeks to trace how ideas of (population)
                ‘replacement’ are developed with respect to different racisms
                (Islamophobia, anti-Semitism, anti-Black racism, racism against
                migrants and refugees, etc.); different demographic dimensions
                (with respect to migration, or birthrates and mortality, or
                cultural change and assimilation); different geographical
                locations (Europe; Middle East; North America; South East Asia,
                etc.); different time periods (medieval archives, colonial
                archives, Nazi archives, post-colonial migrations, post-11/9);
                and within different genres (scholarly knowledge production,
                statistics and demographics, literature, memes and social media,
                manifestos, journalism, etc.)
              </p>
              <p>
                We invite submissions that interrogate the different ways in which
                the discourse of replacement has been formulated and mobilized.
              </p>

              <p>
                Contributions might explore some of the following issues, but
                are not limited to them:
              </p>

              <ul>
                <li>
                  National cases examining discourses on replacement. How has
                  the fear of replacement been entertained and mobilized in a
                  particular national context, what national archives and
                  anxieties does it draws on? What role do public intellectuals
                  and authors play in producing and disseminating ideas about
                  replacement?
                </li>
                <li>
                  Histories and genealogies of ideas of replacement. Concerns
                  with population replacement and weaponization of birthrates
                  and migration have a long history, for instance, including
                  medieval Christian concerns about conversos and moriscos, or
                  immediately after the period of Black reconstruction in the US
                  and the emerging notion of ‘White genocide’. We invite
                  submissions exploring the convoluted genealogies of
                  ‘population replacement fears’ from a historical perspective.
                </li>
                <li>
                  The relation between eugenicist ideas, biopower, and theories
                  on replacement. The fear of replacement and its construction
                  of Muslims as plotting to overturn Europe via population
                  replacement more often than not conjures up ‘solutions’ which
                  entail policies and regulations directed at taking control of
                  the make-up of present and future demographic developments,
                  which sometimes includes eugenicist arguments.
                </li>
                <li>
                  The relation between conspiracy theories and race. Many of the
                  ‘proofs’ that a population replacement is currently
                  transpiring in Europe are fabricated documents, quotations,
                  and statistical knowledge, which supposedly reveal that the
                  population replacement is not a ‘natural’ outcome of
                  demographic development but rather a combative strategy of
                  Muslims in complicity with some European elites. Submissions
                  exploring the conceptual and historical relation between the
                  elaboration of conspiracy theories and forms of racial
                  characterizations are welcomed.
                </li>
                <li>
                  The convoluted relations between race, gender, and sexuality
                  within the frame of population replacement discourses. The
                  discourse on replacement mobilizes a wide range of racially
                  constructed ideas such as Muslim hypersexuality, or the innate
                  violent desire to overtake Europe. Within this context, the
                  population replacement discourse engages as well with European
                  rates of natality and thus with natalist European polices
                </li>

                <li>
                  Theoretical and methodological approaches pertaining to the
                  study of population replacement theories. We welcome
                  submissions exploring conceptual tools, theoretical approaches
                  and methods to investigate the formation of the replacement
                  discourse and its recruiting of demographics, statistics,
                  racism, gender, and sexuality.
                </li>

                <li>
                  Far Right, social media, and replacement. The population
                  replacement thesis has found in the internet a ‘fertile’
                  ground to expand and disseminate via blogs, cartoons and
                  memes, while being the site of further re-elaborations. We
                  invite submissions that are focused on exploring the digital
                  culture of replacement.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="speakers-and-respondents" className="bg-theme-gray py-16">
        <div className="container mx-auto">
          <div className="text-center font-bold mb-20 text-xl lg:text-4xl text-theme-2">
            Keynote Speakers and Respondents
          </div>

          <div className="flex flex-col space-y-20">
            {SpeakersData.map((data, i) => (
              <div key={i}>
                <ProfilePair key={i} data={data.speakers} />

                <div className="my-12 space-y-7">
                  <div className="text-xl lg:text-4xl font-semibold">{data.paragraph.title}</div>
                  <div>{data.paragraph.body}</div>
                </div>

                {(i + 1) < SpeakersData.length ? (
                  <SeparatorHeader type="plain" className="mt-20" />
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="programme" className="lg:min-h-screen bg-theme-1 py-32">
        <div className="container max-w-screen-lg mx-auto">
          <div className="flex flex-col items-center">
            <div className="w-full hidden lg:block">
              <div className="mb-5">
                <PDFViewer />
              </div>
              <div className="text-center">
                Click <a className="font-bold" href="http://event.eemq.net/program.pdf" target="_blank" rel="noreferrer">here to download</a>
              </div>
            </div>

            <div
              className="flex lg:hidden flex-col items-center font-bold text-xl text-center space-y-10"
              target="_blank"
            >
              <span>
                Click on the following button
                <br />
                to download the programme
              </span>

              <a
                className="bg-theme-2 uppercase text-white lg:text-3xl rounded-full py-6 px-12"
                href="https://0000ff-eemq.s3-eu-west-1.amazonaws.com/program.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <span className="opacity-80 font-bold">download the programme</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="registration" className="bg-theme-gray py-32">
        <div className="container mx-auto text-center">
          <div className="flex flex-col items-center space-y-10">
            <p className="font-normal lg:text-xl">
              In order to participate in this conference, either as regular
              participant, panelist, respondent or keynote speaker, you must be
              registered. Registration will give you access to the MeetAnyway
              online event space, where you will find more detailed conference
              information. Please note that this is an online conference for all
              participants. Some of the panels and keynote speeches will take
              place on the campus of the University of Amsterdam, and will be
              life streamed in the online event space. Due to Covid-19, it is
              not possible for regular participants to come to the campus of the
              University of Amsterdam.
            </p>

            <a
              className="group bg-theme-2 uppercase text-white lg:text-3xl rounded-full py-6 px-12"
              href="https://www.aanmelder.nl/125401/subscribe"
              target="_blank"
              rel="noreferrer"
            >
              <span className="group-hover:opacity-80 font-bold">register here</span>
            </a>
          </div>
        </div>
      </section>

      <section id="organization" className="bg-theme-1 py-16">
        <div className="container mx-auto">
          <SeparatorHeader className="mb-20">
            Conference Convenors
          </SeparatorHeader>
          <div className="flex flex-col space-y-16">
            {OrganizationData.convenors.map((item, i) => (
              <ProfileCard key={i} data={item} />
            ))}
          </div>

          <SeparatorHeader className="my-20">Organization Team</SeparatorHeader>
          <div className="flex flex-col space-y-16">
            {OrganizationData.team.map((item, i) => (
              <ProfileCard key={i} data={item} />
            ))}
          </div>
        </div>
      </section>

      <section id="institutional-affiliations" className="bg-theme-gray">
        <div className="container mx-auto py-40">
          <div className="flex justify-center">
            <div className="grid lg:grid-cols-2 gap-20">
              <div className="flex flex-col space-y-4">
                <div className="font-bold text-lg">University of Amsterdam</div>
                <div>
                  <img
                    className="max-w-full"
                    src={UniversityOfAmsterdamImage}
                    alt="University of Amsterdam"
                  />
                </div>
              </div>

              <div className="flex flex-col space-y-4">
                <div className="font-bold text-lg">
                  Political Sociology: Power, Place, and Dierence
                </div>
                <div>
                  <img
                    className="max-w-full"
                    src={PoliticalSociologyImage}
                    alt="Political Sociology: Power, Place, and Dierence"
                  />
                </div>
              </div>

              <div className="flex flex-col space-y-4">
                <div className="font-bold text-lg">
                  Netherlands Organization
                  <br />
                  for Scientific Research NWO
                </div>
                <div>
                  <img
                    className="max-w-full"
                    src={NetherlandsOrganizationImage}
                    alt="Netherlands Organization for Scientific Research NWO "
                  />
                </div>
              </div>

              <div className="flex flex-col space-y-4">
                <div className="font-bold text-lg">
                  Race Religion Secularism network
                </div>
                <div>
                  <img
                    className="max-w-full"
                    src={RaceReligionSecularismImage}
                    alt="Race Religion Secularism network"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default IndexPage
