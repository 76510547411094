import React from 'react'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import PDFFile from '../data/program.pdf'
import '@react-pdf-viewer/core/lib/styles/index.css'

const PDFViewer = () => {
  return (
    <div className="flex flex-col space-y-5">
      <div className="aspect-w-3 aspect-h-4">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer fileUrl={PDFFile} />
        </Worker>
      </div>
    </div>
  )
}

export default PDFViewer
