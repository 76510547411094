import React from 'react'
import PropTypes from 'prop-types'

const SeparatorHeader = ({ children, type, ...props }) => {
  switch (type) {
    case 'first': {
      return (
        <div {...props}>
          <div className="flex items-center">
            <div className="pr-10 font-bold text-xl lg:text-4xl text-theme-2">
              {children}
            </div>
            <div className="bg-theme-2 h-0.5 flex-1 opacity-20"></div>
          </div>
        </div>
      )
    }

    case 'plain': {
      return (
        <div {...props}>
          <div className="flex items-center">
            <div className="bg-theme-2 h-0.5 flex-1 opacity-20"></div>
          </div>
        </div>
      )
    }

    default: {
      return (
        <div {...props}>
          <div className="flex items-center">
            <div className="bg-white h-0.5 flex-1"></div>
            <div className="px-10 font-bold text-xl lg:text-4xl text-theme-2">
              {children}
            </div>
            <div className="bg-white h-0.5 flex-1"></div>
          </div>
        </div>
      )
    }
  }
}

SeparatorHeader.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
}

export default SeparatorHeader
