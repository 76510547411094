import JasminZineImage from '../images/jasmin-zine.png'
import NadiaFadilImage from '../images/nadia-fadil.png'
import MattiasGardellImage from '../images/mattias-gardell.png'
import AnyaTopolskiImage from '../images/anya-topolskiimage.png'
import FalguniShethImage from '../images/falguni-sheth.png'
import LuisManuelHernándezAguilarImage from '../images/luis-manuel-hernandez-aguilar.png'

export default [
  {
    speakers: [
      {
        name: 'Jasmin Zine',
        description: `Jasmin Zine is Professor of Sociology & Muslim Studies at Wilfrid Laurier University. She is author of the forthcoming book: Under Siege:
        Islamophobia and the 9/11 Generation based on a 6-year study funded by the Social Sciences and Humanities Research Council
  (SSHRC). Dr. Zine is currently working on another SSHRC funded study mapping the Canadian Islamophobia Industry. She has worked
  as a consultant with the Oce for Democratic Institutions and Human Rights at the Organization for Security and Cooperation in
  Europe (ODHIR/OSCE), the Council of Europe (COE), and the United Nations Educational, Scientific and Cultural Organization (UNESCO)
  on developing guidelines for educators and policy-makers on combating Islamophobia and anti-Muslim racism. Dr. Zine is a faculty
  member in Critical Muslim Studies Institute on Decolonial Struggles and Liberation Theologies in Granada, Spain. She is co-founder of
  the International Islamophobia Studies Research Association (ISSRA).
        `,
        image: JasminZineImage,
      },
      {
        name: 'Nadia Fadil',
        description: `Nadia Fadil works as an Associate Professor at the IMMRC (Interculturalism, Migration and Minorities Research Centre) at the University
        of Leuven. After having obtained a PhD at this same institute, she has been affiliated as a Postdoctoral Jean Monnet Research Fellow at
  the European University Institute (2008-2009), a Visiting Fellow at the University of California Berkeley (2011-2012), a Fulbright Visiting
  Fellow at Columbia University (2018) and an FWO Postdoctoral fellow at the KU Leuven (2009-2012). Her work centers on Islam in
  Europe (taking Brussels as ethnographic site), both as a lived tradition as well as an object of regulation. She draws on this empirical
  question to reflect on a vast set of theoretical issues such as subjectivity and power, ethical selfhood, postcoloniality, governmentality,
  race and secularism. Her most recent publications include Secular Bodies, Aects and Emotions. European configurations (with
  Monique Scheer and Birgitte Scheplern Johansen, Bloomsbury 2019) and Radicalization in Belgium and the Netherlands. Critical
  perspectives on Violence and Security (with Martijn de Koning and Francesco Ragazzi, IB Tauris 2019). She has also been active as a
  columnist and writer in the Belgian press and is a board member of a few organizations working on migration, multiculturalism and
  social inequality in Brussels`,
        image: NadiaFadilImage,
      },
    ],
    paragraph: {
      title: `Islamophobia and the Politics of Replacement`,
      body: `
        This talk will examine the discursive genealogy of the ‘politics of replacement’ as it
        pertains to the propagation of Islamophobic narratives in specific global contexts. The
        trope of replacement resonates in biological/bio-political terms as well as in civilizational
        discourses that situate Muslims as demographic and cultural threats in various national
        contexts. In western societies, threats of Muslim ‘invaders’ and ‘demographic jihad’
        have been used as alibis for violence against Muslims by white nationalists. These
        ideologies further serve to shore up the securitization and banishment of Muslims
        through sparking fear, racial anxieties, and moral panics. The association of Islam and
        Muslims with death, destruction, and social, cultural, and political upheaval underwrite
        specific discursive and political campaigns globally. In countries where Muslims have
        been historically rooted and persecuted as minoritized communities, replacement fears
        are weaponized through eugenicist ideologies that authorize forced sterilization,
        systemic repression, ethnic cleansing, and genocide. This talk will map some of the ways
        that ideologies of replacement are manifesting within various Islamophobic registers.
      `
    }
  },
  {
    speakers: [
      {
        name: 'Mattias Gardell',
        description: `Mattias Gardell is Nathan Söderblom Professor in Comparative Religion, and Co-Director of Research at the Centre for the
    Multidisciplinary Studies of Racism at Uppsala University, Sweden. He took his PhD at Stockholm University in 1995 on the PhD
Dissertation Countdown to Armageddon: Louis Farrakhan and the Nation of Islam based on several years of fieldwork and interviews in
the segregated Black American inner-cities. His post-doctoral research has continued to draw on ethnographic methods, interviews,
and text analysis in exploring the difficult terrain shaped by the intersections of religion, politics, racism, and violence in various
empirical fields, including white radical nationalism, white power culture, occult fascism, political Islam, human bombs, torture history,
anti-Muslim racism (Islamophobia), white racist political violence, the entangled history of racism and religion, the affective dimensions
of radical nationalism, contemporary fascist fiction, white racist lone wolf tactics, and anti-Black, anti-Roma, and anti-Muslim racisms in
Sweden. He has published eleven research monographs, and more than a hundred articles, anthology chapters and reports. His latest
publications are Lone Wolf Race Warriors and White Genocide (Cambridge 2021), and “‘The Girl Who Was Chased by Fire’: Violence and
Passion in Contemporary Swedish Fascist Fiction”, Fascism (XX:1, 2021, doi:10.1163/22116257-10010004).`,
        image: MattiasGardellImage,
      },
      {
        name: 'Anya Topolski',
        description: `Anya Topolski is an Associate Professor in ethics and political philosophy at the Radboud University Nijmegen.  She is the principle
investigator for the Race-Religion Constellation Project and coordinator of the Race, Religion, Secularism Network
(www.racereligionresearch.org). Her current research is in the field of critical philosophy of race and focuses on the race-religion
intersection in European. Her areas of expertise are: racism, political philosophy, ethics, European identity and exclusion, gender,
antisemitism and Islamophobia, political theology, Jewish thought, Arendt, Levinas, Judeo-Christianity.`,
        image: AnyaTopolskiImage,
      },
    ],
    paragraph: {
      title: `Lone Wolves, Hero Politics, and White Genocide`,
      body: `
        When Brenton Tarrant live-streamed his massacre of fifty-one Muslims in Christchurch,
        New Zealand, in March 2019, he was but one in a series of lone-acting white men
        committing violent crime to further the white radical nationalist aim to save the white
        race from extinction and establish a white ethnostate. In fact, this kind of political
        violence has been sufficiently commonplace to award the attacker their own epithet
        within the milieu of white radical nationalism: ‘lone wolf’, a metaphor loaded with
        romantic notions of the potency and lethality of the free-roaming outcast, suiting the
        hero politics idealized in the milieu. From where did white nationalists get the notion of
        an ongoing white genocide happening on their watch? How did they come to the
        conclusion that ‘resistance’ against a perceived invasion and occupation of ‘white’
        territory should be launched by individual ‘lone wolves’ performing ghastly massacres
        on noncombatants with whom they had no prior relation? How come slaughtering
        innocent children is construed as a heroic act that a perpetrator wants to broadcast to
        the world? This talk will follow the trail of the lone wolf in the landscape of white
        nationalism and trace the genealogies of the white genocide/great replacement
        discourse by engaging with the fascists themselves and analyzing the material they
        produce.
      `
    }
  },
  {
    speakers: [
      {
        name: 'Falguni A. Sheth',
        description: `Falguni A. Sheth is Associate Professor in WGSS. She holds a B.A. in Rhetoric from UC Berkeley, and a Ph.D. in Philosophy from the New
School for Social Research. She has published numerous articles and two books, Race, Liberalism, and Economics (coedited, U.
Michigan Press, 2004) and Toward a Political Philosophy of Race (SUNY Press, 2009), which considers how racial divisions preserve state
power. Her next book, Unruly Women: Race, Neoliberalism, and the Hijab (Oxford University Press forthcoming 2021) explores the racial
dynamics of juridical discourses that discipline Muslim women of color and Black Muslim women in the United States. Sheth’s areas of
teaching and research include postcolonial theory, transnational feminist studies, critical philosophy of race and race studies, feminist
political theory, anticolonial and empire studies, and political and legal philosophy. Sheth’s research explores the intersections of
violence, security, and political ontology as these lead to technologies of race and racialization as a strategy of political and social
management of various populations. Tackling these issues requires the integration of the philosophy of race, critical race theory,
postcolonial feminist theory, and political philosophy to expand the scope of feminist theory to include “feminist security studies of
race.” By illuminating how the legacies of war, violence, and colonialism are transformed via law, public policies, and cultural
institutions to appear neutral, just, and liberal-democratic, her work reveals the mechanisms by which legal and cultural institutions work to maintain a veneer of security on behalf of their dominant populations.`,
        image: FalguniShethImage,
      },
      {
        name: 'Luis Manuel Hernández Aguilar',
        description: `Luis Manuel Hernández Aguilar is a researcher at the University of Amsterdam, working on the project EnGendering Europe’s Muslim
Question. He holds a Ph.D. in Sociology by the Goethe-University Frankfurt am Main. Among his publications: (2018) Governing Muslims
and Islam in Contemporary Germany, Race, Time, and the German Islam Conference, Leiden & Boston: Brill: (2020) The Interior Frontiers
of Germany, On Recursive History and Ritual Male Circumcision. Journal of Muslims in Europe, 10(1), 22–44; (Bracke & Hernández Aguilar
2020) “They love death as we love life”: The “Muslim Question” and the biopolitics of replacement, British Journal of Sociology, 71(4):
680-701.`,
        image: LuisManuelHernándezAguilarImage,
      },
    ],
    paragraph: {
      title: `The Epistemology of Violence,
        Racial Dismissal, and the Politics of Belonging
      `,
      body: `
        In the U.S., violence is publicly registered so as to function in opposition to law, order,
        and security, while promoting violence via state institutions to manage Blacks, Muslims
        and other precarious populations. For example, phenomena such as ‘gun violence,’ is a
        form of framing a very specific kind of violence in order to legitimize other forms of ‘gun

        violence’ as seen in military or immigration policies. This dialectic approach to violence is
        crucial to the US’s politics of belonging. This is part of what I call the ‘epistemology of
        violence.’ Another example of the epistemology of violence is directed toward Black and
        Muslim women, and occurs under the auspices of religious discrimination cases. The
        legal procedures that address discrimination cases often result in ‘dismissal.’ I argue that
        dismissal is both a form of juridical action and a form of managing women of color who
        resist the mandate to reproduce cultural/liberal expectations of (mildly) feminist
        autonomy and independence.
      `
    }
  },
]
